import React, { useState } from 'react';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SendIcon from '@mui/icons-material/Send';
import { getToken } from '../utils/tokenHelper';

const ChatWithAI = () => {
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');
    const [chatHistory, setChatHistory] = useState([]);

    const handleChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = getToken();
        console.log('Retrieved token:', token);  // Log the retrieved token
        if (!token) {
            console.error('No token found');
            setResponse('Error: No token found');
            return;
        }

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const raw = JSON.stringify({
            "message": message
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        try {
            const response = await fetch("https://devapi.quickcare.ng/api/v1/ai/chat", requestOptions);
            if (response.ok) {
                const result = await response.json();
                setResponse(result.output);
                setChatHistory(prevHistory => [...prevHistory, { message, response: result.output }]);
                setMessage('');  // Clear the input field
            } else {
                const errorText = await response.text();
                setResponse(`Error: ${errorText}`);
            }
        } catch (error) {
            setResponse(`Error: ${error.message}`);
        }
    };

    return (
        <div className='w-full h-full flex flex-col items-center relative pb-[500px]'>
            <div className='flex flex-col items-center justify-center p-4 w-full'>
                <div className='mt-20 flex items-center justify-center w-full md:px-5'>
                    <p className='py-1 px-2 rounded-lg bg-[#CDE9D3] text-sm md:text-base'>
                        Messages are generated by AI from QuickCare. You can improve the quality by sending us feedback. Click to learn more.
                    </p>
                </div>
                <div className='mt-10 w-full pb-16'>
                    {chatHistory.map((chat, index) => (
                        <div key={index} className='mb-4 flex flex-col gap-3 pb-6 w-full'>
                            <p className='text-white font-medium self-end py-2 px-3 rounded-lg bg-[#02390E] text-lg'>
                            {chat.message}
                            </p>
                            <div className='w-2/3'>
                            <p className='text-white font-medium py-2 px-3 rounded-lg bg-[#728176] text-lg w-fit'>
                                {chat.response}
                            </p>
                            </div>
                            
                        </div>
                    ))}
                </div>
            </div>

            <form onSubmit={handleSubmit} className='bottom-0 left-0 fixed w-full flex h-16 bg-[#ECEDEE] items-center justify-between px-3 z-20
            lg:px-20'>
                <InsertEmoticonIcon />
                <input
                    type="text"
                    name="message"
                    value={message}
                    onChange={handleChange}
                    placeholder="Type a Message"
                    className='w-4/5 bg-white text-[#777777] p-3 rounded-lg outline-0'
                />
                <button type="submit" className='chat-submit'>
                    <SendIcon />
                </button>
            </form>
        </div>
    );
};

export default ChatWithAI;
