import React, { useEffect, useState } from 'react';
import Header from '../component/Header';
import Sidebar from '../component/Sidebar';
import data from '../component/data/profile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import { Link } from 'react-router-dom';
import { getToken } from '../utils/tokenHelper';


const Health = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const [profile, setProfile] = useState({ first_name: '', last_name: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState('');

    useEffect(() => {
        const token = getToken();
        console.log('Retrieved token:', token);  // Log the retrieved token
        if (!token) {
            console.error('No token found');
            setResponse('Error: No token found');
            return;
        }


        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);


        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch("https://devapi.quickcare.ng/api/v1/user/profile", requestOptions)
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        })
        .then((result) => {
            console.log(result); // Log the result to the console
            setProfile(result);
            setLoading(false);
        })
        .catch((error) => {
            setError(error);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }


    

    return (
        <div className='w-full bg-[#F9FAFB]'>
            <div className='fixed w-full'>
                <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />  
            </div>

            <div className={`pt-16 md:pt-20 w-full ${isSidebarOpen ? 'md:pt-20 md:w-3/4 lg:w-4/5' : 'md:w-3/4 lg:w-4/5 xl:w-5/6'}`}>
                <div className={`${isSidebarOpen ? 'md:block z-50' : 'hidden md:block z-50'}`}>
                    <Sidebar />
                </div>

                <div className='pb-96 md:ml-48 p-6 xl:ml-60  w-full flex flex-col gap-7 
                lg:flex-row mt-4  lg:justify-between lg:h-[1000px]'>
                  <div className='flex flex-col gap-4 w-full
                  lg:w-1/2 lg:pr-6'>
                    <p className='text-lg font-semibold'>
                        Hello {profile.data.first_name}
                    </p>

                    <div className='flex flex-col gap-2 border border-gray-300 rounded-lg p-3 bg-white items-start w-full'>
                        <p className='font-semibold'>
                            Need help?
                        </p>

                        <p className='text-sm'>
                            Experiencing illness or require a follow-up? 
                             Let us know your symptoms for a quick diagnosis.
                        </p>

                        <Link to='/consultation' className='w-1/2 bg-[#069125] text-white text-sm font-medium rounded p-2
                        flex items-center justify-center'>
                            Start a consultation
                        </Link>
                    </div>
                  </div>

                  <div className='lg:w-1 lg:h-[950px] bg-[#c3c3c3]'>

                  </div>

                  <div className='w-full flex flex-col gap-4 
                  lg:w-1/2 '>
                    {data.map((item, index) => (
                        <div key={index} className='w-full flex flex-col border brorder-gray-300 rounded-lg bg-white text-xs 
                        p-3 
                        md:text-base'>
                            <div className='w-full flex items-center justify-between'>
                                <div className='flex items-center gap-2'>
                                    <img src={item.profileImage} alt="" className='w-5 h-5'/>
                                    <p className='font-semibold'>
                                    {item.Name}
                                    </p>
                                    <p className='text-gray-400'>
                                    {item.Age}
                                    </p>
                                </div>

                                <button className=' p-1 border border-gray-300 rounded'>
                                    <MoreVertIcon />
                                </button>
                            </div>

                            <div className='mt-3 flex w-full gap-2'>
                                <FormatQuoteOutlinedIcon />

                                <p className=''>
                                {item.Message}
                                </p>
                            </div>
                            
                           
                        </div>
                    ))}
                  </div>
                </div>
            </div>
        </div>
    );
};

export default Health;
