import React, { useEffect, useState } from 'react';
import { getToken } from '../utils/tokenHelper';

const UserProfile = () => {
  const [profile, setProfile] = useState({ first_name: '', last_name: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState('');

  useEffect(() => {
    const token = getToken();
    console.log('Retrieved token:', token);  // Log the retrieved token
    if (!token) {
        console.error('No token found');
        setResponse('Error: No token found');
        return;
    }


    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);


    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://devapi.quickcare.ng/api/v1/user/profile", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then((result) => {
        console.log(result); // Log the result to the console
        setProfile(result);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h1>User Profile</h1>
      <p>First Name: {profile.data.first_name}</p>
      <p>Last Name: {profile.data.last_name}</p>
    </div>
  );
};

export default UserProfile;
