import React from 'react'
import Logo from '../../images/logo.png'
import Facebook from '../../images/path14.png'
import Twitter from '../../images/Vector.png'
import Instagram from '../../images/Vector (1).png'
import Linkdln from '../../images/linkedin_symbol.svg.png'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Chat from '../../images/Group 1000002507.png'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className='mt-20 bg-[#069125] 
        p-4
        md:px-8 lg:px-16
         flex flex-col relative  gap-6 md:p-8 w-full md:mt-48 lg:mt-52'>
            <div className='w-full flex flex-col gap-5 md:flex-row items-start md:justify-between lg:gap-16'>
                <div className='flex flex-col gap-3 text-white text-sm md:w-1/3'>
                    <img src={Logo} alt="" className='w-[150px]'/>

                    <p>
                    Quick Care is your go-to online platform for swift health solutions. 
                    With a user-friendly interface, find instant remedies for common health queries. 
                    From symptoms to treatments, get concise information tailored to your needs. 
                    Your health matters, and Quick Care makes it easier to stay informed and make informed decisions.
                    </p>
                </div> 

                <div className='flex flex-col gap-3 text-white md:w-1/3'>
                    <p className='text-xl font-medium'>
                   Terms and Policies
                    </p>

                        <Link to='/terms&conditions'>
                        Terms & Conditions
                        </Link>

                        <Link to='/privacy&policies'>
                        Privacy & Policies
                        </Link>
                </div>

                <div className='flex flex-col gap-3 text-white text-sm md:w-1/3'>
                    <p className='text-xl font-medium'>
                    Our Services
                    </p>

                    <p className='mt-3'>
                    Medication Guides
                    </p>

                    <p>
                    Medication Information
                    </p>

                    <p>
                    Symptom Checker
                    </p>

                    <p>
                    Disease Information
                    </p>

                    <p>
                    Health Reminders and Tracking
                    </p>

                    <p>
                    Preventive Care Guides
                    </p>
                </div>
            </div>
           

          <div className='flex items-center gap-3 '>
                <img src={Facebook} alt="" className='w-6 h-6'/>
                <img src={Twitter} alt="" className='w-6 h-6'/>
                <img src={Instagram} alt="" className='w-6 h-6'/>
                <img src={Linkdln} alt="" className='w-6 h-6'/>
          </div>

            <img src={Chat} alt="" className='w-20 h-20 absolute bottom-3 right-5
            md:bottom-3 md:right-16'/>
        </div>
    )
}

export default Footer
