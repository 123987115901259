import React, { useState } from 'react';
import Logo from "../../images/logo.png";
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className='w-full flex items-center justify-between p-4
        md:px-8 lg:px-16'>
            <Link to='/'>
            <img src={Logo} alt="" className='w-28 h-7'/> 
            </Link>
           
            <div className={`md:flex gap-10 items-center font-medium ${isMenuOpen ? 'absolute flex py-4 flex-col gap-1 top-20 left-0 w-full bg-[#069125] text-white' 
            : 'hidden'}`}>
                <a href='#services'>
                    Services
                </a>

                <a href='#benefits'>
                    Benefits
                </a>

                <a href='#about-us'>
                    About Us
                </a>
            </div>

            <button className='bg-[#069125] text-white font-medium px-3 py-2 rounded-lg'>
                Pay with Airtime
            </button>

            <div className='md:hidden p-1 border border-black rounded' onClick={toggleMenu}>
                {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </div>
        </div>
    );
};

export default Navbar;
