import React, { useState } from 'react';
import Header from '../component/Header';
import Sidebar from '../component/Sidebar';
import AllConsultant from '../component/consultant/AllConsultant';
import ACtiveConsultant from '../component/consultant/ActiveConsultant';
import EndedConsultant from '../component/consultant/EndedConsultant';


const History = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const [isAll, setIsAll] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const [isEnded, setIsEnded] = useState(false);

    const toggleAll = () => {
        setIsAll(true);
        setIsActive(false);
        setIsEnded(false);
    };

    const toggleActive = () => {
        setIsAll(false);
        setIsActive(true);
        setIsEnded(false);
    };

    const toggleEnded = () => {
        setIsAll(false);
        setIsActive(false);
        setIsEnded(true);
    };

    return (
        <div className='w-full bg-[#F9FAFB]'>
            <div className='fixed w-full'>
                <Header isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />  
            </div>

            <div className={`pt-16 md:pt-20 w-full ${isSidebarOpen ? 'md:pt-20 md:w-3/4 lg:w-4/5' : 'md:w-3/4 lg:w-4/5 xl:w-5/6'}`}>
                <div className={`${isSidebarOpen ? 'md:block z-50' : 'hidden md:block z-50'}`}>
                    <Sidebar />
                </div>

                <div className='md:ml-48 p-6 xl:ml-60  w-full'>
                    <div className='w-full flex items-center justify-between '>
                       <div className='w-full flex gap-6 text-sm items-center md:text-base lg:gap-6 lg:px-6'>
                            <button onClick={toggleAll} className={isAll ? 'text-[#069125] pb-1 border-b border-[#069125] font-medium ' : ''}>
                            All Consultations
                            </button>
                            <button onClick={toggleActive} className={isActive ? 'text-[#069125] pb-1 border-b border-[#069125] font-medium' : ''}>
                            Active Consultations
                            </button>
                            <button onClick={toggleEnded} className={isEnded ? 'text-[#069125] pb-1 border-b border-[#069125] font-medium' : ''}>
                            Ended Consultations
                            </button>
                       </div>

                    </div>

                    {isAll && (
                        <div>
                           <AllConsultant />
                        </div>
                    )}

                    {isActive && (
                        <div>
                            <ACtiveConsultant />
                        </div>
                    )}

                    {isEnded && (
                        <div>
                            <EndedConsultant />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default History;
