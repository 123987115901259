import Image from '../../images/Ellipse 1402.png'

const data = [
    {
        Description: "Navigating the vast landscape of health information can be daunting, but this portal makes it easy and accessible. It offers a wealth of well-organized, scientifically-backed data that helps users make informed health decisions. The site’s interface is user-friendly, and its resources, are top-notch. A reliable go-to for anyone looking to enhance their health literacy.",  
        Name: "Catherine Ezegwu",
        Job: "Owner, Cathy’s place",
        Image: Image // Assign Catherine's image
    },
    {
        Description: "This health information portal stands out with its comprehensive and up-to-date content. Whether you're a medical professional or a curious layperson, it offers valuable insights and practical advice on a wide range of health topics. The articles are easy to understand, and the site's layout ensures that you find the information you need quickly. Highly recommended for anyone seeking trustworthy health information.",  
        Name: "Harrison Akintola",
        Job: "Business Man",
        Image: Image // Assign Harrison's image
    },
    {
        Description: "I've been a patient at this healthcare clinic for years, and I continue to be impressed by the level of professionalism and expertise displayed by the staff. Whether it's a routine check-up or a more serious medical issue, I always feel like I'm in good hands here. Thank you for providing such top-notch care!",  
        Name: "Njika Godwin",
        Job: "Software Engineer",
        Image: Image // Assign Njika's image
    },
    {
        Description: "I am continually impressed by the quality of care provided by the healthcare professionals at this facility. They always take the time to explain my treatment options and involve me in the decision-making process. Thank you for your dedication to patient-centered care.",  
        Name: "Akinbola Rukayat",
        Job: "Economist",
        Image: Image // Assign Rukayat's image
    }
];

export default data;
