import React from 'react'
import data from '../data/allconsultant'
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AllConsultant = () => {
    return (
        <div className='w-full mt-5 border  rounded bg-white flex flex-col gap-5 text-xs
        md:text-base lg:ml-6'>
            <div className='w-full flex items-center justify-between gap-2  bg-[#EEF1F8] py-1 px-2 rounded-t text-[#A0A0A0]
            py-2'>
                <p className='w-1/4'>
                Issue
                </p>

                <p className='w-1/4 text-center md:text-start'>
                Consultation Number
                </p>

                <p className='w-1/4 text-center md:text-start'>
                Date and time
                </p>

                <p className='w-1/4'>

                </p>
            </div>

            <div className='w-full flex flex-col gap-2 '>
                {data.map((item, index) => (
                    <div key={index} className='w-full flex items-center justify-between gap-2 font-semibold
                    border-b border-gray-300 pb-2 py-1 px-2'>
                        <p className='w-1/4'>
                        {item.Issues}
                        </p>

                        <p className='w-1/4'>
                        {item.ConsultationNumber}
                        </p>

                        <p className='w-1/4 flex flex-col text-center md:text-start'>
                        <span>
                        {item.Date}
                        </span>
                        <span className='text-[#A0A0A0]'>
                        {item.Time}
                        </span>
                        </p>

                        <div className='w-1/4 flex items-center justify-center'>
                            <button className=' p-1 border border-gray-300 rounded'>
                                <MoreVertIcon />
                            </button>
                        </div>

                        
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AllConsultant
