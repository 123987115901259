import React from 'react'
import Image20 from '../../images/image 20 (1).png'
import Image9 from '../../images/image 9.png'
import Image19 from '../../images/image 19.png'

const Benefit = () => {
    return (
        <div className='w-full flex bg-[#069125] flex-col gap-4 p-4
        md:px-8 lg:px-16
        lg:flex-row lg:gap-6'>
          <div className='w-full flex items-start gap-2 flex-col
          '>
            <p className='text-2xl text-[#CDE9D3] font-semibold'>
            BENEFITS
            </p>

            <p className='text-2xl text-white font-semibold'>
            We make quality Healthcare Accessible
            </p>

            <p className='text-[#CDE9D3] lg:mt-1'>
            We believe that reliable health related information should be accessible to all without restrictions. We offer reliable access to health services, via our web app, anywhere & anytime.
            </p>

            <div className='flex flex-col gap-2 lg:gap-3'>
                <div className='flex gap-1 items-center'>
                    <div className='w-4 h-4 bg-[#D9D9D9] rounded-full'></div>
                    <p className='text-white'>Customer centred approach</p>
                </div>
            </div>

            <button className='bg-[#CDE9D3] px-3 py-2 rounded font-medium
            lg:mt-4'>
            Book an Appointment
            </button>
          </div>

          <div className='flex flex-col w-full gap-3'>
            <div className='flex w-full gap-2 items-center justify-between'>
                <img src={Image20} alt="" className='w-[200px] h-28 md:w-1/2 md:h-40'/>
                <img src={Image9} alt="" className='w-[200px] h-28 md:w-1/2 md:h-40'/>
            </div>

            <img src={Image19} alt="" className='w-full'/>
          </div>
        </div>
    )
}

export default Benefit
