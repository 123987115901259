import profile from "../../images/profile.png";

const data = [
    {
      Name: "Ibukunoluwa A.",  
      Age: "27 Yrs Old",
      Message: "I had this severe headache that has persisted for months, but due to my schedule I wasn’t able to access quick medical consultation. Thanks to Quickcare, I received treatments and consultation from my place of comfort.",
      profileImage: profile
    },
    {
      Name: "Ibukunoluwa A.",  
      Age: "27 Yrs Old",
      Message: "I had this severe headache that has persisted for months, but due to my schedule I wasn’t able to access quick medical consultation. Thanks to Quickcare, I received treatments and consultation from my place of comfort.",
      profileImage: profile
    },
    {
      Name: "Ibukunoluwa A.",  
      Age: "27 Yrs Old",
      Message: "I had this severe headache that has persisted for months, but due to my schedule I wasn’t able to access quick medical consultation. Thanks to Quickcare, I received treatments and consultation from my place of comfort.",
      profileImage: profile
    },
];

export default data;
