const data = [
    {
      Issues: "Typhoid",
      ConsultationNumber: "123 456 7890",
      Date: "6 July, 2023",
      Time: "1:00 pm"
    },
    {
      Issues: "Malaria",
      ConsultationNumber: "081 234 2455",
      Date: "7 July, 2023",
      Time: "5:00 pm"
    },
    {
      Issues: "Migraine",
      ConsultationNumber: "081 234 2455",
      Date: "11 July, 2023",
      Time: "3:00 pm"
    },
    {
        Issues: "Sore throat",
        ConsultationNumber: "081 234 2455",
        Date: "11 July, 2023",
        Time: "3:00 pm"
    },
    {
        Issues: "Typhoid",
        ConsultationNumber: "123 456 7890",
        Date: "6 July, 2023",
        Time: "1:00 pm"
      },
      {
        Issues: "Malaria",
        ConsultationNumber: "081 234 2455",
        Date: "7 July, 2023",
        Time: "5:00 pm"
      },
      {
        Issues: "Migraine",
        ConsultationNumber: "081 234 2455",
        Date: "11 July, 2023",
        Time: "3:00 pm"
      },
      {
          Issues: "Sore throat",
          ConsultationNumber: "081 234 2455",
          Date: "11 July, 2023",
          Time: "3:00 pm"
      }  
  ];
  
  export default data;
  