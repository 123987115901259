import React, { useEffect, useState } from 'react';
import { getToken } from '../utils/tokenHelper';
import Profile from "../images/profile.png"
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

const PersonalInfo = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (event) => {
        const imageFile = event.target.files[0];
        if (imageFile) {
            const imageUrl = URL.createObjectURL(imageFile);
            setSelectedImage(imageUrl);
        }
    };

    const [profile, setProfile] = useState({ first_name: '', last_name: '', address: '', date_of_birth: '', 
    gender: '', email: '', country: '',});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState('');

    useEffect(() => {
        const token = getToken();
        console.log('Retrieved token:', token);  // Log the retrieved token
        if (!token) {
            console.error('No token found');
            setResponse('Error: No token found');
            return;
        }


        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);


        const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch("https://devapi.quickcare.ng/api/v1/user/profile", requestOptions)
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
            }
            return response.json();
        })
        .then((result) => {
            console.log(result); // Log the result to the console
            setProfile(result);
            setLoading(false);
        })
        .catch((error) => {
            setError(error);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }



    return (
        <div className='w-full h-fit flex flex-col gap-4  border border-gray-300 rounded mt-4 bg-white mb-96'>
            <div className='w-full border-b border-gray-300'>
                <div className=' p-2 lg:p-6 flex w-full items-center justify-between text-sm gap-3
                md:w-2/3 md:text-base lg:w-3/5 xl:w-2/5'>
                    <div className='flex flex-col gap-1'>
                        <p className='font-semibold '>
                        Profile Photo
                        </p>

                        <p className='text-gray-500'>
                            This image will be displayed on your profile
                        </p>

                        <label htmlFor="image-upload" className="cursor-pointer border border-[#069125] w-2/3 text-[#069125] font-medium py-2 
                        flex items-center gap-1 px-1 rounded text-sm justify-center
                        hover:bg-[#069125] hover:text-white">
                            <ImageOutlinedIcon />
                            <span>
                                Change Photo
                            </span>
                        </label>
                        <input id="image-upload" type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
                    </div>

                    <img src={selectedImage || Profile} alt="Profile" className='w-16 h-16  rounded-full'/>
                </div>
            </div>
           

            <div className='px-2 lg:px-6 pt-1 pb-6 lg:pb-10 flex flex-col gap-3 w-full text-sm md:text-base'>
                <p className='font-semibold'>
                Personal Information
                </p>

                <div className=''>
                    <p className='text-gray-400'>
                    Name
                    </p>

                    <p>
                    {profile.data.last_name} {profile.data.first_name}
                    </p>
                </div>

                <div className=''>
                    <p className='text-gray-400'>
                    Email
                    </p>

                    <p>
                    {profile.data.email}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'>
                    Date of Birth
                    </p>

                    <p>
                    {profile.data.date_of_birth}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'> 
                    Gender
                    </p>

                    <p className='capitalize'>
                    {profile.data.gender}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'>
                    Home Address
                    </p>

                    <p>
                    {profile.data.address}
                    </p>
                </div>

                <div>
                    <p className='text-gray-400'>
                   Country
                    </p>

                    <p className='capitalize'>
                    {profile.data.country}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PersonalInfo
