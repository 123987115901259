const data = [
    {
      Plan: "Basic Plan",  
      Price: "3,000.00",
      Feature: [
      "Access to Basic Health Resources", 
      "Appointment Rescheduling", 
      "Unlimited Text-Based consultations", 
    ]
    },
    {
      Plan: "Standard Plan",
      Price: "5,000.00",
      Feature: [
      "All Basic Plan Features", 
      "Video Consultations", 
      "Priority Support ", 
      "Prescription Refills", 
    ]
    },
    {
      Plan: "Premium Plan",
      Price: "10,000.00",
      Feature: [
      "All Standard Plan features", 
      "Dedicated personal physician", 
      "Priority appointment scheduling", 
      "Annual comprehensive health check-up", 
    ]
    },
    
  ];
  
  export default data;
  