const data = [
    {
      Issues: "Typhoid",
      ConsultationStatus: "Ongoing",
      Date: "6 July, 2023",
      Time: "1:00 pm"
    },
    {
      Issues: "Malaria",
      ConsultationStatus: "Pending",
      Date: "7 July, 2023",
      Time: "5:00 pm"
    },
    {
      Issues: "Migraine",
      ConsultationStatus: "Pending",
      Date: "11 July, 2023",
      Time: "3:00 pm"
    }, 
    {
        Issues: "Typhoid",
        ConsultationStatus: "Ended",
        Date: "11 July, 2023",
        Time: "3:00 pm"
    },
    {
        Issues: "Malaria",
        ConsultationStatus: "Ended",
        Date: "11 July, 2023",
        Time: "3:00 pm"
    },  
  ];
  
  export default data;
  