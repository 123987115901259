import React, { useState } from 'react';
import Stereo from '../images/stereo.png';
import Logo from '../images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { setToken } from '../utils/tokenHelper';  // Import the helper function

const Login = () => {
    const [phone_number, setPhone_number] = useState('234');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    const handlePhoneNumberChange = (e) => {
        const input = e.target.value;
        // Ensure the phone number always starts with '234'
        if (input.startsWith('234')) {
            setPhone_number(input);
        } else if (!input) {
            setPhone_number('234'); // Reset to '234' if the input is cleared
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const loginData = {
            phone_number: phone_number,
            password: password,
        };

        try {
            const response = await fetch('https://devapi.quickcare.ng/api/v1/user/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Login successful:', result);
                console.log('Token from response:', result.access_token);  // Log the token to check its presence
                setToken(result.access_token);  // Store the token
                console.log('Token stored:', result.access_token);  // Log the stored token
                setMessage('Login successful!');
                setIsError(false);
                setTimeout(() => {
                    navigate('/health');  // Navigate to another page
                }, 2000);  // Wait for 2 seconds before navigating
            } else {
                const errorText = await response.text();
                console.error('Login failed:', errorText);
                setMessage('Login failed: Invalid email or password.');
                setIsError(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('An error occurred. Please try again.');
            setIsError(true);
        }
    };

    return (
        <div className='w-full md:flex flex-row h-screen'>
            <div className='w-full flex flex-col gap-4 p-7 md:w-1/2 md:items-start md:justify-center md:gap-16 lg:gap-8'>
                <Link to='/'>
                    <img src={Logo} alt="" className='w-[150px] h-[30px]' />
                </Link>

                <div className='mt-4 flex w-full flex-col gap-2'>
                    <h1 className='text-2xl font-semibold'>Login</h1>

                    <div className='flex flex-row gap-6'>
                        <p>New to QuickCare?</p>
                        <Link to='/signup' className='text-[#45B139]'>Create Account</Link>
                    </div>
                </div>

                {message && (
                    <div className={`mt-4 p-2 text-center ${isError ? 'text-red-500' : 'text-green-500'}`}>
                        {message}
                    </div>
                )}

                <form onSubmit={handleSubmit} className='flex flex-col w-full gap-4 items-center md:gap-8'>
                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="tel"
                            value={phone_number}
                            onChange={handlePhoneNumberChange}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Phone Number'
                        />
                    </div>

                    <div className='w-full flex flex-col gap-1'>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='w-full border-b border-[#C4C4C4] px-1 py-1 outline-0 text-[#777777]'
                            placeholder='Password'
                        />
                    </div>

                    <div className='flex items-center w-full justify-between mt-2'>
                        <div className='flex items-center gap-2'>
                            <input type="checkbox" className='w-5 h-5' />
                            <p className='text-sm'>Remember me</p>
                        </div>

                        <Link to='/forgot-password' className='text-sm'>
                            Forgot Password
                        </Link>
                    </div>

                    <button type='submit' className='w-full flex items-center justify-center p-2 rounded bg-[#069125] font-medium text-white tracking-wider'>
                        Login
                    </button>

                    
                </form>k
            </div>

            <img src={Stereo} alt="" className='hidden md:flex w-1/2' />
        </div>
    );
};

export default Login;
