import React from 'react';
import Logo from "../images/logo.png";
import SearchIcon from '@mui/icons-material/Search';
import Profile from "../images/profile.png";
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';

const Header = ({ isSidebarOpen, toggleSidebar }) => {
    return (
        <div className='w-full flex flex-row items-center justify-between p-3 md:pb-4 bg-white md:border-b-2 '>
            <div className='md:hidden' onClick={toggleSidebar}>
                {isSidebarOpen ? <ClearIcon /> : <MenuIcon />}
            </div>

            <img src={Logo} alt="Logo" className='w-32 h-15'/>

            <div className='hidden md:flex gap-1  border border-gray-300 rounded-lg w-80 items-center justify-between bg-[#F4F4F4] lg:w-1/4'>
                <input type="text" placeholder='Search' className='w-11/12 p-2 outline-0 border-0 text-gray-500 bg-transparent'/>
                <div className='bg-[#CDE9D3] p-2 text-xl rounded-r-lg text-[#069125]'>
                    <SearchIcon />
                </div>
            </div>

            <img src={Profile} alt="" />
        </div>
    );
};

export default Header;
