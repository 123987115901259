import React from 'react'
// import Safaricom from '../../images/safaricom-2.png'
import Mtn from '../../images/mtn.png'
import Airtel from '../../images/airtel.png'
import Etisalat from '../../images/9mobile.png'
import TelegramIcon from '@mui/icons-material/Telegram';
import PaperImage from '../../images/image 21.png'

const Patners = () => {
    return (
        <div className='w-full flex flex-col gap-4  items-center p-4
        md:px-8 lg:px-16'>
           <div className='w-full flex flex-col gap-4 items-center pb-8 border-b border-[#c3c3c3]'>
                <p className='text-[#069125] text-xl font-semibold tracking-wider	'>
                OUR PARTNERS
                </p>

                <div className='flex items-center w-full justify-between px-3 
                md:w-2/3 lg:w-1/3'>
                    <img src={Mtn} alt="" />
                    <img src={Airtel} alt="" />
                    <img src={Etisalat} alt="" />
                </div>
           </div> 

           <div className='w-full flex flex-col gap-3 mt-3 items-center relative'>
            <p className='text-[#069125] text-xl font-semibold tracking-wider'>
            LET US SEND YOU OFFERS
            </p>

            <div className='flex w-full gap-3 px-3 md:w-1/2 lg:w-1/3'>
                <input type="email" name="" id="" placeholder='Input your Email in Here'
                className='border-[#069125] border rounded p-2 w-5/6 text-[#A29E9E] outline-0'/>

                <button className='w-10 h-10 p-1 text-white bg-[#069125] flex items-center justify-center rounded'>
                    <TelegramIcon />
                </button>
            </div>

            <img src={PaperImage} alt="" className='absolute top-4 left-6 lg:left-60 lg:top-0 lg:w-2/3 lg:h-[300px]'/>
           </div>
        </div>
    )
}

export default Patners
