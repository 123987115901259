import React from 'react'
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const Service = () => {
    return (
        <div className='w-full flex flex-col gap-2 items-center mt-20 
        p-4 pb-16
        md:px-8 lg:px-16'>
            <p className='text-xl text-[#069125] font-bold tracking-wider'>
            OUR SERVICES  
            </p>

            <p className='text-2xl font-semibold tracking-wider text-center lg:text-start'>
            Comprehensive Medical Solution
            </p>

            <div className='grid grid-cols-2 gap-4 text-xs lg:w-1/2 xl:w-1/3
            md:grid-cols-2 auto-rows-max p-2'>
                <div className='flex gap-1 items-center'>
                    <div className='flex p-1 bg-[#64B978] text-white rounded rotate-180'>
                        <WbIncandescentIcon />
                    </div>

                    <p>
                    E-Booking for Diagnosis
                    </p>
                </div>

                <div className='flex gap-1 items-center'>
                    <div className='flex p-1 bg-[#D1AC00] text-white rounded rotate-180'>
                        <WbIncandescentIcon />
                    </div>

                    <p>
                    Visual Consultation
                    </p>
                </div>

                {/* <div className='flex gap-1 items-center'>
                    <div className='flex p-1 bg-[#F6BE9A] text-white rounded rotate-180'>
                        <WbIncandescentIcon />
                    </div>

                    <p>
                    Visual Consultation
                    </p>
                </div>

                <div className='flex gap-1 items-center'>
                    <div className='flex p-1 bg-[#E0807B] text-white rounded rotate-180'>
                        <WbIncandescentIcon />
                    </div>

                    <p>
                    Visual Consultation
                    </p>
                </div>

                <div className='flex gap-1 items-center'>
                    <div className='flex p-1 bg-[#AAB7DA] text-white rounded rotate-180'>
                        <WbIncandescentIcon />
                    </div>

                    <p>
                    Visual Consultation
                    </p>
                </div>

                <div className='flex gap-1 items-center'>
                    <div className='flex p-1 bg-[#4F5D2F] text-white rounded rotate-180'>
                        <WbIncandescentIcon />
                    </div>

                    <p>
                    Visual Consultation
                    </p>
                </div> */}
            </div>

            <div className='mt-3 w-full bg-[#15421F] flex flex-col gap-1 items-center text-white p-3 rounded-lg md:w-11/12
            xl:w-2/3'>
                <p className='text-xl md:text-2xl font-semibold'>
                Online Medical Records
                </p>

                <p className='text-base text-center md:w-2/3 md:text-lg'>
                Quickcare aims to be your ultimate health companion accessible anywhere, anytime
                </p>

                <div className='text-[#15421F] bg-white p-1 rounded-full w-10 h-10 flex items-center justify-center mt-2'>
                    <PlayArrowIcon />
                </div>
            </div>
        </div>
    )
}

export default Service
