import React from 'react';
import data from '../data/activeconsultant';


const ACtiveConsultant = () => {
    // Filter out items with ConsultationStatus of "Ended"
    const filteredData = data.filter(item => item.ConsultationStatus !== "Ended");

    return (
        <div className='w-full mt-5 border rounded bg-white flex flex-col gap-5 text-xs md:text-base lg:ml-6 mb-96'>
            <div className='w-full flex items-center justify-between gap-2 bg-[#EEF1F8] py-1 px-2 rounded-t text-[#A0A0A0] py-2'>
                <p className='w-1/3'>
                Issue
                </p>
                <p className='w-1/3 text-start'>
                Date and time
                </p>
                <p className='w-1/3 text-start'>
                Consultation Status
                </p>
            </div>
            <div className='w-full flex flex-col gap-2 '>
                {filteredData.map((item, index) => (
                    <div key={index} className='w-full flex items-center justify-between gap-2 font-semibold border-b border-gray-300 pb-2 py-1 px-2'>
                        <p className='w-1/3'>
                        {item.Issues}
                        </p>
                        <p className='w-1/3 flex flex-col text-start'>
                        <span>
                        {item.Date}
                        </span>
                        <span className='text-[#A0A0A0]'>
                        {item.Time}
                        </span>
                        </p>
                        <p className='w-1/3'>
                        {item.ConsultationStatus}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ACtiveConsultant;
