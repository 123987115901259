import React from 'react'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const ContactUs = () => {
    return (
        <div className='w-full flex bg-[#069125] flex-col gap-4 p-4
        md:px-8 lg:px-16
        lg:flex-row lg:gap-6'>
          <div className='w-full flex items-start gap-5 flex-col
          '>
            <p className='text-2xl text-white font-semibold'>
            Get In Touch
            </p>

            {/* <p className='text-white lg:mt-1 w-[400px]'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            </p> */}

            <div className='flex items-center gap-1'>
                <div className='w-10 h-10 rounded-full p-1 flex items-center justify-center bg-[#CDE9D3]'>
                <LocalPhoneIcon />
                </div>

                <div className='text-white text-sm'>
                    <p>
                    Give Us a Call
                    </p>

                    <p>
                    07034345324
                    </p>
                </div>
            </div>

            <button className='bg-[#CDE9D3] px-3 py-2 rounded font-medium
            lg:mt-4'>
            Contact Us
            </button>
          </div>

          
        </div>
    )
}

export default ContactUs
